import classNames from 'classnames';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import { FormattedMessage } from '../../util/reactIntl';
import ValidationError from '../ValidationError/ValidationError';

import css from './CustomMultipleSelectField.module.css';

const customizeTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--heroTitleColor)',
  },
});

const customStyles = (selectInputCustomStyle) => {
  return {
    ...selectInputCustomStyle,
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: '#272cbd',
        borderRadius: '16px',
        color: 'var(--colorWhite)',
        paddingLeft: '6px',
      };
    },
    placeholder: base => ({
      ...base,
      fontWeight: 'var(--fontWeightRegular)',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'var(--colorWhite)',
    }),
    multiValueRemove: base => ({
      ...base,
      ':hover': {
        ...base[':hover'],
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
        backgroundColor: 'var(--marketplaceColorDark)',
        '> svg': {
          fill: 'var(--colorWhite)',
        },
      },
    }),
  }
};

const CustomMultipleSelectFieldComponent = props => {
  const {
    selectInputCustomStyle,
    rootClassName,
    className,
    id,
    label,
    input,
    meta,
    options,
    isMulti,
    isSearchable,
    isRequired,
    closeMenuOnSelect,
    customOnChange,
    handleSearchData,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const { valid, invalid, touched, error } = meta;
  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const classes = classNames(css.root || rootClassName, className, {
    [css.selectSuccess]: valid,
    [css.selectError]: hasError,
  });
  const selectProps = { id, ...input, ...meta, ...rest };
  const noticeLabel = (
    <span className={css.notice}>
      {isRequired ? (
        <FormattedMessage id="CustomFieldTextInput.required" />
      ) : (
        <FormattedMessage id="CustomFieldTextInput.optional" />
      )}
    </span>
  );
  const handleOnChange = values => {
    if (customOnChange) {
      customOnChange(values);
    }
    input.onChange(values);
  };

  return options ? (
    <div className={classes}>
      {label ? (
        <label htmlFor={id}>
          {label} {noticeLabel}
        </label>
      ) : null}
      <Select
        {...selectProps}
        isMulti={isMulti}
        isSearchable={isSearchable}
        options={options}
        styles={customStyles(selectInputCustomStyle)}
        theme={customizeTheme}
        components={{ IndicatorSeparator: () => null }}
        hideSelectedOptions={false}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={handleOnChange}
        onInputChange={handleSearchData}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  ) : null;
};

const CustomMultipleSelectField = props => {
  return <Field component={CustomMultipleSelectFieldComponent} {...props} />;
};

CustomMultipleSelectFieldComponent.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  isMulti: true,
  isSearchable: true,
  options: null,
  closeMenuOnSelect: false,
  customOnChange: null,
  selectInputCustomStyle: {},
  handleSearchData: null,
};

CustomMultipleSelectFieldComponent.propTypes = {
  rootClassName: string,
  selectInputCustomStyle: object,
  className: string,
  id: string.isRequired,
  label: string,
  meta: object.isRequired,
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  isMulti: bool,
  isSearchable: bool,
  options: arrayOf(
    shape({
      value: string.isRequired,
      label: node.isRequired,
    })
  ),
};

export default CustomMultipleSelectField;
