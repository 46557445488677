import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes } from '../../../../util/types';
import { isFieldForCategory, isFieldForListingType, SKIPPABLE_FIELDS } from '../../../../util/fieldHelpers';
import { maxLength, required, composeValidators } from '../../../../util/validators';

// Import shared components
import {
  Form,
  Button,
  FieldSelect,
  FieldTextInput,
  Heading,
  CustomExtendedDataField,
  FieldDateInput,
} from '../../../../components';
// Import modules from this directory
import css from './EditListingDetailsForm.module.css';
import CustomMultipleSelectField from '../../../../components/CustomMultipleSelectField/CustomMultipleSelectField';
import moment from 'moment';



import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./DatePickerStyles.css"; // Custom styles for UI improvement

const currentYear = moment().year();
const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

const DateOfBirthPicker = ({ input, meta }) => {
  const [focused, setFocused] = useState(false);
  const [selectedYear, setSelectedYear] = useState(input.value ? moment(input.value).year() : currentYear);

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);
    setSelectedYear(newYear);

    if (input.value) {
      const updatedDate = moment(input.value).year(newYear);
      input.onChange(updatedDate);
    }
  };

  return (
    <div className='detailsNEwData' style={{ position: "relative" }}>
      <label>Date of birth</label>
      <SingleDatePicker
        date={input.value ? moment(input.value) : null} // Ensure Formik state is used
        onDateChange={(date) => input.onChange(date ? date.toDate() : null)} // Update Formik state
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id="dob-picker"
        numberOfMonths={1}
        isOutsideRange={() => false}
        displayFormat="DD-MM-YYYY"
        renderMonthElement={({ month, onMonthSelect }) => (
          <div className="calendar-header">
            {/* Month Selector */}
            <select
              className="custom-dropdown"
              value={month.month()}
              onChange={(e) => onMonthSelect(month, parseInt(e.target.value, 10))}
            >
              {moment.months().map((label, value) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>

            {/* Year Selector */}
            <select className="custom-dropdown" value={selectedYear} onChange={handleYearChange}>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        )}
      />
      {meta.touched && meta.error && <span className="error">{meta.error}</span>}
    </div>
  );
};

const TITLE_MAX_LENGTH = 60;

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingDetailsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

// Field component that either allows selecting listing type (if multiple types are available)
// or just renders hidden fields:
// - listingType              Set of predefined configurations for each listing type
// - transactionProcessAlias  Initiate correct transaction against Marketplace API
// - unitType                 Main use case: pricing unit
const FieldSelectListingType = props => {
  const {
    name,
    listingTypes,
    hasExistingListingType,
    onListingTypeChange,
    formApi,
    formId,
    intl,
  } = props;
  const hasMultipleListingTypes = listingTypes?.length > 1;

  const handleOnChange = value => {
    const selectedListingType = listingTypes.find(config => config.listingType === value);
    formApi.change('transactionProcessAlias', selectedListingType.transactionProcessAlias);
    formApi.change('unitType', selectedListingType.unitType);

    if (onListingTypeChange) {
      onListingTypeChange(selectedListingType);
    }
  };
  const getListingTypeLabel = listingType => {
    const listingTypeConfig = listingTypes.find(config => config.listingType === listingType);
    return listingTypeConfig ? listingTypeConfig.label : listingType;
  };

  return hasMultipleListingTypes && !hasExistingListingType ? (
    <>
      <FieldSelect
        id={formId ? `${formId}.${name}` : name}
        name={name}
        className={css.listingTypeSelect}
        label={intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
        validate={required(
          intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeRequired' })
        )}
        onChange={handleOnChange}
      >
        <option disabled value="">
          {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypePlaceholder' })}
        </option>
        {listingTypes.map(config => {
          const type = config.listingType;
          return (
            <option key={type} value={type}>
              {config.label}
            </option>
          );
        })}
      </FieldSelect>
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  ) : hasMultipleListingTypes && hasExistingListingType ? (
    <div className={css.listingTypeSelect}>
      <Heading as="h5" rootClassName={css.selectedLabel}>
        {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
      </Heading>
      <p className={css.selectedValue}>{getListingTypeLabel(formApi.getFieldState(name)?.value)}</p>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </div>
  ) : (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  );
};

// Finds the correct subcategory within the given categories array based on the provided categoryIdToFind.
const findCategoryConfig = (categories, categoryIdToFind) => {
  return categories?.find(category => category.id === categoryIdToFind);
};

/**
 * Recursively render subcategory field inputs if there are subcategories available.
 * This function calls itself with updated props to render nested category fields.
 * The select field is used for choosing a category or subcategory.
 */
const CategoryField = props => {
  const { currentCategoryOptions, level, values, prefix, handleCategoryChange, intl } = props;

  const currentCategoryKey = `${prefix}${level}`;

  const categoryConfig = findCategoryConfig(currentCategoryOptions, values[`${prefix}${level}`]);

  return (
    <>
      {currentCategoryOptions ? (
        <FieldSelect
          key={currentCategoryKey}
          id={currentCategoryKey}
          name={currentCategoryKey}
          className={css.listingTypeSelect}
          onChange={event => handleCategoryChange(event, level, currentCategoryOptions)}
          label={intl.formatMessage(
            { id: 'EditListingDetailsForm.categoryLabel' },
            { categoryLevel: currentCategoryKey }
          )}
          validate={required(
            intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryRequired' },
              { categoryLevel: currentCategoryKey }
            )
          )}
        >
          <option disabled value="">
            {intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryPlaceholder' },
              { categoryLevel: currentCategoryKey }
            )}
          </option>

          {currentCategoryOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </FieldSelect>
      ) : null}

      {categoryConfig?.subcategories?.length > 0 ? (
        <CategoryField
          currentCategoryOptions={categoryConfig.subcategories}
          level={level + 1}
          values={values}
          prefix={prefix}
          handleCategoryChange={handleCategoryChange}
          intl={intl}
        />
      ) : null}
    </>
  );
};

const FieldSelectCategory = props => {
  useEffect(() => {
    checkIfInitialValuesExist();
  }, []);

  const { prefix, listingCategories, formApi, intl, setAllCategoriesChosen, values } = props;

  // Counts the number of selected categories in the form values based on the given prefix.
  const countSelectedCategories = () => {
    return Object.keys(values).filter(key => key.startsWith(prefix)).length;
  };

  // Checks if initial values exist for categories and sets the state accordingly.
  // If initial values exist, it sets `allCategoriesChosen` state to true; otherwise, it sets it to false
  const checkIfInitialValuesExist = () => {
    const count = countSelectedCategories(values, prefix);
    setAllCategoriesChosen(count > 0);
  };

  // If a parent category changes, clear all child category values
  const handleCategoryChange = (category, level, currentCategoryOptions) => {
    const selectedCatLenght = countSelectedCategories();
    if (level < selectedCatLenght) {
      for (let i = selectedCatLenght; i > level; i--) {
        formApi.change(`${prefix}${i}`, null);
      }
    }
    const categoryConfig = findCategoryConfig(currentCategoryOptions, category).subcategories;
    setAllCategoriesChosen(!categoryConfig || categoryConfig.length === 0);
  };

  return (
    <CategoryField
      currentCategoryOptions={listingCategories}
      level={1}
      values={values}
      prefix={prefix}
      handleCategoryChange={handleCategoryChange}
      intl={intl}
    />
  );
};

// Add collect data for listing fields (both publicData and privateData) based on configuration
const AddListingFields = props => {
  const { listingType, listingFieldsConfig, selectedCategories, formId, intl } = props;
  const targetCategoryIds = Object.values(selectedCategories);

  const fields = listingFieldsConfig.reduce((pickedFields, fieldConfig) => {
    const { key, schemaType, scope } = fieldConfig || {};
    const namespacedKey = scope === 'public' ? `pub_${key}` : `priv_${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isProviderScope = ['public', 'private'].includes(scope);
    const skipField = SKIPPABLE_FIELDS.includes(key);
    const isTargetListingType = isFieldForListingType(listingType, fieldConfig);
    const isTargetCategory = isFieldForCategory(targetCategoryIds, fieldConfig);

    return isKnownSchemaType && isProviderScope && isTargetListingType && isTargetCategory && !skipField
      ? [
        ...pickedFields,
        <CustomExtendedDataField
          key={namespacedKey}
          name={namespacedKey}
          fieldConfig={fieldConfig}
          defaultRequiredMessage={intl.formatMessage({
            id: 'EditListingDetailsForm.defaultRequiredMessage',
          })}
          formId={formId}
        />,
      ]
      : pickedFields;
  }, []);

  return <>{fields}</>;
};

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form: formApi,
        handleSubmit,
        onListingTypeChange,
        intl,
        invalid,
        pristine,
        selectableListingTypes,
        selectableCategories,
        hasExistingListingType,
        pickSelectedCategories,
        categoryPrefix,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
      } = formRenderProps;

      const { listingType, transactionProcessAlias, unitType } = values;
      const [allCategoriesChosen, setAllCategoriesChosen] = useState(false);

      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const hasCategories = selectableCategories && selectableCategories.length > 0;
      const showCategories = listingType && hasCategories;

      const showTitle = hasCategories ? allCategoriesChosen : listingType;
      const showDescription = hasCategories ? allCategoriesChosen : listingType;
      const showListingFields = hasCategories ? allCategoriesChosen : listingType;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const hasMandatoryListingTypeData = listingType && transactionProcessAlias && unitType;
      const submitDisabled =
        invalid || disabled || submitInProgress || !hasMandatoryListingTypeData;
        console.log('invalid',invalid)
      const Credentials = [
        {
          value: 'CPR training',
          label: 'CPR training',
        },
        {
          value: 'Certified nursing assistant',
          label: 'Certified nursing assistant',
        },
        {
          value: 'Certified home health aide',
          label: 'Certified home health aide',
        },

        {
          value: 'Wound Care',
          label: 'Wound Care',
        },
        {
          value: 'Experience with medical equipment',
          label: 'Experience with medical equipment',
        },
        {
          value: 'Comfortable with pets',
          label: 'Comfortable with pets',
        },
        {
          value: 'Other',
          label: 'Other',
        },

      ]

      const Service = [
        {
          value: "Transportation",
          label: "Transportation"
        },
        {
          value: "Personal care",
          label: "Personal care"
        },
        {
          value: "Medical management",
          label: "Medication Management"
        },
        {
          value: "Cooking/Meal preparation",
          label: "Cooking/Meal preparation"
        },
        {
          value: "Light house cleaning",
          label: "Light house cleaning"
        },
        {
          value: "Live-in home care",
          label: "Live-in home care"
        },
        {
          value: "Overnight care",
          label: "Overnight care"
        },
        {
          value: "Daytime care",
          label: "Daytime care"
        },
        {
          value: "Hospice services",
          label: "Hospice services"
        },
        {
          value: "Dementia care",
          label: "Dementia care"
        },
        {
          value: "Alzheimer's/Memory Care",
          label: "Alzheimer's/Memory Care"
        },
        {
          value: "Groceries/Errands",
          label: "Groceries/Errands"
        },
        {
          value: "Bathing/Dressing",
          label: "Bathing/Dressing"
        },
        {
          value: "Mobility assistance / physical activity",
          label: "Mobility assistance / physical activity"
        },
        {
          value: "Feeding",
          label: "Feeding"
        },
        {
          value: "Companionship",
          label: "Companionship"
        },
        {
          value: "Medical transportation",
          label: "Medical transportation"
        },
        {
          value: "Home health",
          label: "Home health"
        },
        {
          value: "Help with pets",
          label: "Help with pets"
        }
      ]

      const LanguagesData = [
        {
          value: 'English',
          label: 'English',
        },
        {
          value: 'Spanish',
          label: 'Spanish',
        },
        {
          value: 'French',
          label: 'French',
        },

        {
          value: 'Tagalog',
          label: 'Tagalog',
        },
        {
          value: 'Other',
          label: 'Other',
        },
        

      ]

   console.log('values',values)
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <FieldSelectListingType
            name="listingType"
            listingTypes={selectableListingTypes}
            hasExistingListingType={hasExistingListingType}
            onListingTypeChange={onListingTypeChange}
            formApi={formApi}
            formId={formId}
            intl={intl}
          />

          {showCategories ? (
            <FieldSelectCategory
              values={values}
              prefix={categoryPrefix}
              listingCategories={selectableCategories}
              formApi={formApi}
              intl={intl}
              allCategoriesChosen={allCategoriesChosen}
              setAllCategoriesChosen={setAllCategoriesChosen}
            />
          ) : null}

          {values?.listingType === "care-giver-new" ?
            <div className={css.careGiver}>
              <FieldTextInput
                id={`${formId}title`}
                name="title"
                className={css.fromField}
                type="text"
                label={"First Name"}
                placeholder={"Enter First Name"}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required("First name is required"), maxLength60Message)}
                autoFocus={autoFocus}
              />

              <FieldTextInput
                id={`${formId}last_name`}
                name="last_name"
                className={css.fromField}
                type="text"
                label={"Last Name"}
                placeholder={"Enter last name"}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required("Last name is required"), maxLength60Message)}
                autoFocus={autoFocus}
              />

<Field name="date_of_birth" component={DateOfBirthPicker} />

           
                {/* <FieldDateInput
                 className={classNames(css.fromField, css.dob )}
                  name="date_of_birth"
                  id="date-picker"
                  label={"Date of birth"}
                  placeholderText={"date of birth"}
                  // isDayBlocked={isDayBlocked}


                /> */}


              <FieldTextInput
                id={`${formId}zip_code`}
                name="zip_code"
                className={css.fromField}
                type="text"
                label={"Zip Code"}
                placeholder={"Enter Zip Code"}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required("Zip Code is required"), maxLength60Message)}
                autoFocus={autoFocus}
              />


              <FieldSelect
                id={`${formId}gender`}

                name="gender"
                className={css.fromField}

                label={"Gender"}
                placeholder={"Enter Gender"}
                validate={required("Gender")}

              // onChange={handleOnChange}
              >
                <option disabled value="">
                  Select Gender
                </option>
                <option value={"Male"}>
                  Male
                </option>
                <option value={"Female"}>
                  Female
                </option>
                <option value={"Other"}>
                  Other
                </option>
              </FieldSelect>


              {/* <FieldSelect
                 id={`${formId}-Credentials`}
                 className={css.fromField}
                 name="Credentials"
                 label={"Credentials"}
                 validate={required(
                   "Credentials is required",
                 )}
                 placeholder={"Select Credentials"}

              // onChange={handleOnChange}
              >
                <option disabled value="">
                Select Credentials
                </option>
                {Credentials?.map(data=>{
                  return <option key={data.value} value={data.value}>
                  {data.label}
                </option>
                })}
               
              </FieldSelect> */}

              <CustomMultipleSelectField
                  className={classNames(css.fromField, css.serviveProvideField)}
                id={`${formId}-Credentials`}
                name="Credentials"
                label={"Credentials"}
                validate={required(
                  "Service is required",
                )}
                placeholder={"Select Credentials"}
                options={Credentials}
                
                isRequired
                isMulti
              />

{/*               

              <CustomGroupMultipleSelectField
                id={`${formId}-Credentials`}
                name="Credentials"
                label={"Credentials"}
                validate={required(
                  "Credentials is required",
                )}
                placeholder={"Select Credentials"}
                options={Credentials}
                isRequired
                isMulti
              /> */}

              <FieldSelect
                id={`${formId}experience`}

                name="experience"
                className={css.fromField}

                label={"Years of experience"}

                validate={required("Years of experience Required")}

              // onChange={handleOnChange}
              >
                <option disabled value="">
                  Years of experience
                </option>
                <option value={"0-1"}>
                  0 - 1 year
                </option>
                <option value={"2-5"}>
                  2 - 5 years
                </option>
                <option value={"6-10"}>
                  6 - 10 years

                </option>

                <option value={"10+"}>
                  10+ years



                </option>
              </FieldSelect>

              <CustomMultipleSelectField
                  className={classNames(css.fromField, css.serviveProvideField)}
                id={`${formId}-Serviceprovided`}
                name="Serviceprovided"
                label={"Service provided"}
                validate={required(
                  "Service is required",
                )}
                placeholder={"Select Service"}
                options={Service}
                
                isRequired
                isMulti
              />

              {/* <FieldTextInput
                id={`${formId}hourly_rate`}
                name="hourly_rate"
                className={css.fromField}
                type="text"
                label={"Starting Hourly Rate "}
                placeholder={"Enter Starting Hourly Rate "}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required("Starting Hourly Rate is required"), maxLength60Message)}
                autoFocus={autoFocus}
              /> */}

              <FieldTextInput
                id={`${formId}phoneNumber`}
                name="phoneNumber"
                className={css.fromField}
                type="text"
                label={"Phone Number"}
                placeholder={"Phone Number"}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required("Phone Number is required"), maxLength60Message)}
                autoFocus={autoFocus}
              />


              <FieldSelect
                id={`${formId}education`}

                name="education"
                className={css.fromField}

                label={"Education"}
                placeholder={"Education"}
                validate={required("Education is required")}

              // onChange={handleOnChange}
              >
                <option disabled value="">
                  Select Education
                </option>
                <option value={"High School Diploma / GED"}>High School Diploma / GED</option>
                <option value={"Some College (No Degree Earned)"}>Some College (No Degree Earned)</option>
                <option value={"Associate Degree"}>Associate Degree</option>
                <option value={"Bachelor’s Degree"}>Bachelor’s Degree</option>
                <option value={"Graduate Degree (Master’s or Higher)"}>Graduate Degree (Master’s or Higher)</option>
              </FieldSelect>
              {values?.education &&
              <FieldTextInput
                id={`${formId}education_collage`}
                name="education_collage"
                className={css.fromField}
                type="text"
                label={"Name of College or Graduate School"}
                placeholder={"Enter name of college or graduate school"}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required("name of college or graduate school required"), maxLength60Message)}
                autoFocus={autoFocus}
              />
    }




<CustomMultipleSelectField
                  className={classNames(css.fromField, css.serviveProvideField)}
                id={`${formId}-languages`}
                name="languages"
                label={"languages"}
                validate={required(
                  "Languages is required",
                )}
                placeholder={"Select languages"}
                options={LanguagesData}
                
                isRequired
                isMulti
              />

              {/* <FieldSelect
                id={`${formId}Languages`}

                name="languages"
                className={css.fromField}

                label={"Languages"}
                placeholder={"Languages"}
                validate={required("Languages is required")}

              // onChange={handleOnChange}
              >
                <option disabled value="">
                  Select languages
                </option>
                <option value={"English"}>English</option>
                <option value={"Spanish"}>Spanish</option>
                <option value={"French"}>French</option>
                <option value={"Tagalog"}>Tagalog</option>
                <option value={"Hindi"}>Hindi</option>
                <option value={"Other"}>Other (free text)</option>

              </FieldSelect> */}

              {values?.languages === "Other" &&
              <FieldTextInput
                id={`${formId}other_lang`}
                name="other_lang"
                className={css.fromField}
                type="text"
                label={"Language (custom)"}
                placeholder={"Enter language"}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required("language required"), maxLength60Message)}
                autoFocus={autoFocus}
              />
    }

              <FieldSelect
                id={`${formId}vaccination`}

                name="vaccination"
                className={css.fromField}

                label={"Covid vaccination"}
                placeholder={"Covid vaccination"}
                validate={required("Covid vaccination is required")}

              // onChange={handleOnChange}
              >
                <option disabled value="">
                  Covid vaccination?
                </option>
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>


              </FieldSelect>
              <FieldSelect
                id={`${formId}flushot`}

                name="flushot"
                className={css.fromField}

                label={"Flu shot"}
                placeholder={"Flu shot"}
                validate={required("Flu shot is required")}

              // onChange={handleOnChange}
              >
                <option disabled value="">
                  Flu shot?
                </option>
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>


              </FieldSelect>

              <FieldTextInput
                id={`${formId}description`}
                name="description"
                className={classNames(css.fromField, css.textAreaBlock)}
                type="textarea"
                label={"Biography"}
                placeholder={"tell clients why you want to be a caregiver, how many years of experience, education, and why they should hire you"}
                validate={required(
                  "Biography is required"
                )}
              />
            </div>
            :
            <>
              {showTitle ? (
                <FieldTextInput
                  id={`${formId}title`}
                  name="title"
                  className={css.fromField}
                  type="text"
                  label={intl.formatMessage({ id: 'EditListingDetailsForm.title' })}
                  placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.titlePlaceholder' })}
                  maxLength={TITLE_MAX_LENGTH}
                  validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                  autoFocus={autoFocus}
                />
              ) : null}

              {showDescription ? (
                <FieldTextInput
                  id={`${formId}description`}
                  name="description"
                  className={css.description}
                  type="textarea"
                  label={intl.formatMessage({ id: 'EditListingDetailsForm.description' })}
                  placeholder={intl.formatMessage({
                    id: 'EditListingDetailsForm.descriptionPlaceholder',
                  })}
                  validate={required(
                    intl.formatMessage({
                      id: 'EditListingDetailsForm.descriptionRequired',
                    })
                  )}
                />
              ) : null}

              {showListingFields ? (
                <AddListingFields
                  listingType={listingType}
                  listingFieldsConfig={listingFieldsConfig}
                  selectedCategories={pickSelectedCategories(values)}
                  formId={formId}
                  intl={intl}
                />
              ) : null}
            </>
          }
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingDetailsForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingDetailsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  pickSelectedCategories: func.isRequired,
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFields,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
